import React from 'react';
import { Route } from 'react-router-dom';
import { PlattCognitiveFaqPage } from '@platt/plugin-platt-cognitive-faq';
import { PlattCognitiveMinutesPage } from '@platt/plugin-platt-cognitive-minutes';

export const faqsAppRoutes = () => {
  return (
    <>
      <Route path="/cognitive-faq" element={<PlattCognitiveFaqPage />} />
      <Route path="/platt-cognitive-minutes" element={<PlattCognitiveMinutesPage/>}/>
    </>
  );
};
