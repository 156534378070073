import React from 'react';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { SidebarItem } from '@backstage/core-components';
import { isCognitiveFaqPluginEnabled } from '@platt/plugin-platt-cognitive-faq';
import { isCognitiveMinutesAvailable } from '@platt/plugin-platt-cognitive-minutes';

export const FaqsSidebarItems = () => {
  const config = useApi(configApiRef);
  return (
    <>
        { isCognitiveFaqPluginEnabled(config) &&
          <SidebarItem icon={QuestionAnswerIcon} to="cognitive-faq" text="FAQ" />
        }
        { isCognitiveMinutesAvailable(config) &&
          <SidebarItem icon={BorderColorIcon} to="platt-cognitive-minutes" text="Meeting" />
        }
    </>
  );
};
