import React from 'react';
import { EntitySwitch, isKind } from '@backstage/plugin-catalog';
import {
  EntityPage as DefaultEntityPage
} from '@platt-internal/backstage-plugin-bootstrap-react';
import {
  EntityPage as FaqsEntityPage
} from '@platt/backstage-plugin-bootstrap-faqs-react';
  
const groupPage = DefaultEntityPage.groupPage({});

const userPage = DefaultEntityPage.userPage({});

const componentPage = DefaultEntityPage.componentPage({
  dfaultEntityPage: DefaultEntityPage.defaultEntityPage({}),
  serviceEntityPage: DefaultEntityPage.serviceEntityPage({}),
  websiteEntityPage: DefaultEntityPage.websiteEntityPage({}),
});

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')} children={DefaultEntityPage.apiPage()} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={DefaultEntityPage.systemPage()} />
    <EntitySwitch.Case if={isKind('domain')} children={DefaultEntityPage.domainPage()} />
    <EntitySwitch.Case if={isKind('resource')} children={DefaultEntityPage.resourceEntityPage()} />
    <EntitySwitch.Case if={isKind('meetingRepo')} children={FaqsEntityPage.meetingRepoPage()} />

    <EntitySwitch.Case>{DefaultEntityPage.defaultEntityPage()}</EntitySwitch.Case>
  </EntitySwitch>  
);
